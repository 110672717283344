import * as React from 'react';
import { SVGProps } from 'react';

const Twitter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} fill="none" viewBox="0 0 48 48" {...props}>
    <path fill="currentColor" d="M36.166 12 15.912 36h-3.98l20.136-24h4.098Z" />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2.2}
      d="M35.09 34.9h-5.376L13.018 13.1h5.51L35.09 34.9Z"
    />
  </svg>
);

export default Twitter;
