import { FC, memo } from 'react';
import styles from './MarketReport.module.less';
import commonstyles from '../common.module.css';
import { Button as ButtonAntd, Col, Row } from 'antd';
import { Button } from 'components/Button';

import { useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import React from 'react';
import Link from 'antd/lib/typography/Link';
import { routes } from 'types/routes';
import { DownOutlined } from '@ant-design/icons';
import { useMarketReport } from 'api/dashboard';
import dayjs from 'dayjs';

const MarketReport: FC = () => {
  const { t } = useTypeSafeTranslation();
  const { data } = useMarketReport();

  return (
    <div className={styles.background}>
      <div className="container">
        <Row className={clsx(commonstyles.root, styles.markets)}>
          <div className={styles.title}>{t('landing_page.market.title')}</div>
          <Row gutter={[90, 0]} className={styles.content}>
            <Col xl={12} lg={12}>
              <a href={data?.items[0]?.link} target="_blank" rel="noopener noreferrer" className={styles.wrapImage}>
                <img src={data?.items[0]?.thumbnail} alt="" />
              </a>
              <a href={data?.items[0]?.link} target="_blank" rel="noopener noreferrer" className={styles.breakNew}>
                <div className={styles.breakImage}>
                  <img src="/images/homev2/slider/weeky_crypto_report.svg" alt="" />
                </div>
                <div className={styles.breakTitle}>
                  <div>{dayjs(data?.items[0]?.pubDate).format('MMM D, YYYY')}</div>
                  <div>{data?.items[0]?.title}</div>
                </div>
              </a>
            </Col>
            <Col className={styles.wrapNews} xl={12} lg={12} md={24} xs={24}>
              <div className={styles.news}>
                {data?.items.slice(0, 3).map((item) => (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={item.pubDate}
                    className={styles.new}
                  >
                    <div>{dayjs(item.pubDate).format('MMM D, YYYY')}</div>
                    <div>{item.title}</div>
                  </a>
                ))}
              </div>
              <div className={styles.wrapButton}>
                <a href="http://marketreports.ultorex.io/" target="_blank" rel="noopener noreferrer">
                  <ButtonAntd icon={<DownOutlined />} shape="circle" type="text" size="large" className="primary" />
                </a>
              </div>
            </Col>
          </Row>
          <div className={styles.wrapButtonTrade}>
            <Link href={`${routes.exchange}`}>
              <div className={styles.btnAvax}>
                <Button size="large" type="primary">
                  <span>{t('landing_page.button.trade')}</span>
                  <span>{t('landing_page.button.start')}</span>
                </Button>
              </div>
            </Link>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default memo(MarketReport);
