import { useQuery, UseQueryOptions } from 'react-query';
import { authRequest, request } from 'api/axios';
import { TReferralRankResponse, TReferralCountResponse, TUserPnlResponse, TMarketResponse } from './types';
import axios from 'axios';

export const useReferralRank = (options?: UseQueryOptions<TReferralRankResponse>) => {
  return useQuery<TReferralRankResponse>(
    '/consumer/referral_rank',
    async () => {
      const { data } = await authRequest.post(`/consumer/referral_rank`);
      return data;
    },
    options
  );
};

export const useReferralCount = (options?: UseQueryOptions<any>) => {
  return useQuery<TReferralCountResponse>(
    '/consumer/referrals/new_count',
    async () => {
      const { data } = await authRequest.post(`/consumer/referrals/new_count`);
      return data;
    },
    options
  );
};

export const useUserPnl = (options?: UseQueryOptions<TUserPnlResponse>) => {
  return useQuery<TUserPnlResponse>(
    '/user/assets/pnl',
    async () => {
      const { data } = await authRequest.post(`/user/assets/pnl`);
      return data;
    },
    options
  );
};
export const useMarketReport = (options?: UseQueryOptions<TMarketResponse>) => {
  return useQuery<TMarketResponse>(
    'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmarketreports.ultorex.io%2Ffeed%2F',
    async () => {
      const { data } = await axios.get(
        `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmarketreports.ultorex.io%2Ffeed%2F`
      );
      return data;
    },
    options
  );
};
