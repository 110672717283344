import { FC } from 'react';
import styles from './MobileApp.module.less';
import commonstyles from '../common.module.css';
import { Col, Row } from 'antd';
import { useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import React from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import GooglePlay from 'assets/svgs/components/GooglePlay';
import AppStore from 'assets/svgs/components/AppStore';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const MobileApp: FC = () => {
  const user = useUser();
  const { t } = useTypeSafeTranslation();
  const { md } = useBreakpoint();
  return (
    <div className={clsx(user.user && commonstyles.background)}>
      <div className="container">
        <Row className={clsx(commonstyles.root)}>
          <div className={clsx(styles.wrapTitle, user && styles.paddingTop)}>
            <span>{t('landing_page.mobile.title_hl')}&nbsp;</span>
            <span>{t('landing_page.mobile.title')}</span>
          </div>
          <Row>
            <Col className={styles.wrapImage} lg={12} md={24} xs={24}>
              <img src="/images/homev2/mobile/mobile-1.png" alt="" />
            </Col>
            <Col className={styles.appDownload} lg={12} md={24} xs={24}>
              <div className={styles.smallTitle}>
                <div>{t('landing_page.mobile.feel_free') + ' '}</div>
                <div>{t('landing_page.mobile.trade_safe')}</div>
              </div>

              <div className={clsx(styles.wrapButton, user && styles.paddingBottom)}>
                {md ? (
                  <>
                    <a
                      className={styles.media}
                      href="https://play.google.com/store/apps/details?id=com.ultorexapp.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GooglePlay />
                    </a>
                    <a
                      className={styles.media}
                      href="https://apps.apple.com/us/app/ultorex/id1625009146"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AppStore />
                    </a>
                  </>
                ) : (
                  <a
                    className={clsx(styles.media, styles.mediaMobile, 'd-flex f-column f-center')}
                    href="https://ultorex-io.app.link/ultorex-mobile"
                    rel="nofollow"
                  >
                    Get Ultorex App
                    <img src="/images/logo.svg" alt="" />
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default MobileApp;
