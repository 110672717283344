import LandingPage from 'modules/LandingPage';
import { GetServerSidePropsContext } from 'next';
import { prefetchSSR } from 'utils/ssr';

(LandingPage as any).theme = 'dark';
export default LandingPage;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  return await prefetchSSR(ctx);
}
