import clsx from 'clsx';
import { FC } from 'react';
import styles from './styles.module.less';
import commonStyles from '../rightPanel.module.less';
import { useTypeSafeTranslation } from 'hooks';
import { Space } from 'antd';
import { routes } from 'types/routes';
import Link from 'next/link';
import { Button } from 'components/Button';
import { Hidden } from '@mui/material';

export const ORBSTop10: FC = () => {
  const { t } = useTypeSafeTranslation();
  return (
    <>
      <div className={clsx(commonStyles.largeItem, styles.root)}>
        <div className={styles.logo}>
          <img src="/images/homev2/orbs-top-10/banner_logo.png" alt="" />
        </div>
      </div>
      <div className={styles.actions}>
        <div className="f-center">
          <h1>ORBS Special Page!</h1>
          <Hidden mdDown>
            <Link href={`${routes.orbsTop10}`} passHref>
              <Button size="large" type="primary">
                <span className="uppercase">Join Now!</span>
              </Button>
            </Link>
          </Hidden>
        </div>

        <p>
          Stake & be in the top 10 to get <span>8.5% APY</span>
        </p>

        <Hidden mdUp>
          <Link href={`${routes.orbsTop10}`} passHref>
            <Button size="middle" type="primary">
              <span className="uppercase">Join Now!</span>
            </Button>
          </Link>
        </Hidden>
      </div>
    </>
  );
};
