import * as React from 'react';
import { SVGProps } from 'react';

const GooglePlay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={234.167} height={69.361} {...props}>
    <g fill="currentColor">
      <path
        d="M9.479 0h215.209a9.507 9.507 0 0 1 9.483 9.479v50.4a9.506 9.506 0 0 1-9.479 9.479H9.479A9.506 9.506 0 0 1 0 59.879v-50.4A9.506 9.506 0 0 1 9.479 0Zm0 3.134a6.363 6.363 0 0 0-6.345 6.345v50.4a6.363 6.363 0 0 0 6.345 6.344h215.209a6.363 6.363 0 0 0 6.345-6.344v-50.4a6.363 6.363 0 0 0-6.345-6.345Z"
        fillRule="evenodd"
      />
      <path
        d="M84.588 38.026H73.624v3.253h7.775c-.384 4.562-4.18 6.507-7.762 6.507a8.689 8.689 0 0 1 .01-17.377 8.438 8.438 0 0 1 5.862 2.351l2.278-2.359a11.582 11.582 0 0 0-8.256-3.255 11.952 11.952 0 1 0 .173 23.9c6.4 0 11.082-4.383 11.082-10.865a9.749 9.749 0 0 0-.2-2.158ZM93.952 38.687a4.674 4.674 0 1 1-4.4 4.648 4.417 4.417 0 0 1 4.4-4.648Zm-.044-3.017a7.682 7.682 0 1 0 7.708 7.708 7.59 7.59 0 0 0-7.708-7.708ZM110.93 38.687a4.674 4.674 0 1 1-4.4 4.648 4.417 4.417 0 0 1 4.4-4.648Zm-.045-3.017a7.682 7.682 0 1 0 7.708 7.708 7.59 7.59 0 0 0-7.708-7.708ZM127.814 38.689c2.024 0 4.1 1.729 4.1 4.681 0 3-2.075 4.654-4.148 4.654a4.365 4.365 0 0 1-4.249-4.626 4.427 4.427 0 0 1 4.294-4.709Zm-.3-3.011a7.691 7.691 0 0 0-.071 15.366 5.076 5.076 0 0 0 4.213-1.866v1.514c0 2.651-1.609 4.237-4.038 4.237a4.26 4.26 0 0 1-3.932-2.735l-2.953 1.234a7.391 7.391 0 0 0 6.909 4.524c4.105 0 7.234-2.586 7.234-8.009v-13.8h-3.221v1.3a5.433 5.433 0 0 0-4.142-1.763ZM150.009 38.614a3.062 3.062 0 0 1 2.842 1.641l-6.879 2.875a4.267 4.267 0 0 1 4.036-4.516Zm-.135-2.954c-3.895 0-7.165 3.1-7.165 7.672a7.429 7.429 0 0 0 7.539 7.708 7.794 7.794 0 0 0 6.435-3.371l-2.655-1.767a4.3 4.3 0 0 1-3.764 2.115 3.952 3.952 0 0 1-3.768-2.328l10.3-4.274-.535-1.253a6.955 6.955 0 0 0-6.388-4.5Z"
        fillRule="evenodd"
      />
      <path d="M137.46 27.941h3.383v22.642h-3.383zM182.823 27.941h3.383v22.642h-3.383zM164.881 27.941h3.383v22.642h-3.383z" />
      <path
        d="M164.881 27.941h8.184a7.234 7.234 0 0 1 7.213 7.213 7.234 7.234 0 0 1-7.213 7.213h-8.184V27.941Zm3.383 3.384v7.659h4.8a3.83 3.83 0 1 0 0-7.659ZM211.186 57.952l9.8-22.292h-3.7l-9.8 22.292Z"
        fillRule="evenodd"
      />
      <path
        d="m212.279 52.849-7.554-17.19h3.7l5.21 11.856h-1.352ZM200.053 50.583h3.383V42.44c.529-8.4-10.119-10.374-14.148-4.32l2.723 1.827c1.97-3.444 7.592-2.652 7.961 1.039a15 15 0 0 0-5.182-.6 5.468 5.468 0 0 0-5.186 4.624 5.61 5.61 0 0 0 1.157 4.366c1.592 1.713 4.205 1.943 6.352 1.358a5.821 5.821 0 0 0 2.94-1.975v1.828Zm0-6.545a5.093 5.093 0 0 1-3.744 3.742c-3.912 1.066-4.885-5.29.928-4.416a14.514 14.514 0 0 1 2.816.676ZM72.687 14.164h-4.989v1.481h3.539a3.275 3.275 0 0 1-3.533 2.961 3.954 3.954 0 0 1 0-7.908 3.84 3.84 0 0 1 2.668 1.07l1.037-1.073a5.27 5.27 0 0 0-3.757-1.481 5.439 5.439 0 1 0 .079 10.877 4.789 4.789 0 0 0 5.043-4.944 4.432 4.432 0 0 0-.091-.982Z"
        fillRule="evenodd"
      />
      <path d="M93.077 9.575h1.54v10.304h-1.54z" />
      <path
        d="M84.454 19.879h1.54v-8.765h2.527v-1.54h-6.595v1.54h2.527ZM74.398 19.879h5.905v-1.54h-4.365v-2.84h3.84v-1.54h-3.84v-2.843h4.365v-1.54h-5.905ZM98.397 19.879h1.54v-8.765h2.527v-1.54H95.87v1.54h2.527ZM111.907 18.606a3.955 3.955 0 1 1 3.858-3.941 3.89 3.89 0 0 1-3.858 3.941Zm0-9.392a5.438 5.438 0 1 0 5.481 5.425 5.528 5.528 0 0 0-5.481-5.426ZM118.925 19.879h1.54v-7.506l4.945 7.506h1.537v-10.3h-1.54v7.5l-4.942-7.5h-1.54ZM21.648 56.964a3.59 3.59 0 0 0 .461-.213l21.159-11.582-5.016-5.009-16.6 16.8Zm22.557-12.307-5.233-5.225 5.228-5.29 7.2 3.927c1.774.967.866 2.177 0 2.651l-7.194 3.938Zm-.941-11.026L22.109 22.09a2.132 2.132 0 0 0-.738-.232l16.876 16.851 5.017-5.078ZM20.13 22.066l17.4 17.371-17.313 17.522a2.188 2.188 0 0 1-.781-1.914l-.157-31.1a2.006 2.006 0 0 1 .852-1.879Z"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default GooglePlay;
