import * as React from 'react';
import { SVGProps } from 'react';

const Telegram = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26.433} height={21.932} {...props}>
    <path
      d="M1.616 9.647c6-2.515 13.254-6.095 19.543-8.514 5.8-2.128 5.708-1.548 4.837 4.257-.677 4.644-1.451 9.288-2.225 13.931-.29 2.225-1.451 3.386-3.87 2.032l-7.546-5.321c-1.064-.871-.774-1.548.1-2.419l6.966-6.579c1.838-1.838.968-2.515-1.161-1.064l-9.578 6.482a4.644 4.644 0 0 1-4.354.484l-3.289-1.161c-2.128-.774-.484-1.645.581-2.128Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Telegram;
