import { FC, memo } from 'react';
import styles from './StepTrading.module.less';
import commonstyles from '../common.module.css';
import { Col, Row } from 'antd';
import { Button } from 'components/Button';

import { useAppDispatch, useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import Link from 'next/link';
import { routes } from 'types/routes';
import { setAuthModal } from 'store/ducks/system/slice';

const StepTrading: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTypeSafeTranslation();
  return (
    <div className="container">
      <Row className={clsx(commonstyles.root)}>
        <Col className={styles.wrapStepInfo} lg={12} md={24} sm={24} xs={24}>
          <div className={styles.wrapTitle}>
            <div className={styles.largeNum}>3</div>
            <div className={styles.textRights}>
              <div className={styles.text}>{t('landing_page.step_steps')}</div>
              <div className={styles.text}>{t('landing_page.step.before')}</div>
              <div className={styles.text}>{t('landing_page.step.trading')}</div>
            </div>
          </div>
          <div className={styles.stepInfo}>
            <div className={styles.step}>
              <span className={styles.number}>1</span>
              <div className={styles.wrapText}>
                <div className={styles.smallTitle}>{t('landing_page.step.step-1')}</div>
                <div className={styles.description}>{t('landing_page.step.step-1-desc')}</div>
              </div>
            </div>
            <div className={styles.step}>
              <span className={styles.number}>2</span>
              <div className={styles.wrapText}>
                <div className={styles.smallTitle}>{t('landing_page.step.step-2')}</div>
                <div className={styles.description}>{t('landing_page.step.step-2-desc')}</div>
              </div>
            </div>
            <div className={styles.step}>
              <span className={styles.number}>3</span>
              <div className={styles.wrapText}>
                <div className={styles.smallTitle}>{t('landing_page.step.step-3')}</div>
                <div className={styles.description}>{t('landing_page.step.step-3-desc')}</div>
              </div>
            </div>
          </div>
          <div className={styles.wrapButtonTrade}>
            <a
              onClick={(e) => {
                e.preventDefault();
                dispatch(setAuthModal({ type: 'signup', screen: '3_step' }));
              }}
            >
              <div className={styles.btnAvax}>
                <Button size="large" type="primary">
                  <span>{t('landing_page.button.register')}</span>
                  <span>{t('landing_page.button.start')}</span>
                </Button>
              </div>
            </a>
          </div>
        </Col>
        <Col className={styles.wrapStepImage} lg={12} md={24} sm={24} xs={24}>
          <div className={styles.stepImage}>
            <p className={styles.middleNumber}>1</p>
            <div className={styles.wrapImage}>
              <img src="/images/homev2/3_step/step_1.svg" alt="" />
              <div>{t('landing_page.step.deposit')}</div>
              <div>{t('landing_page.step.deposit_desc')}</div>
            </div>
          </div>
          <div className={styles.stepImage}>
            <p className={styles.middleNumber}>2</p>
            <div className={styles.wrapImage}>
              <img src="/images/homev2/3_step/step_2.svg" alt="" />
              <div>{t('landing_page.step.perform')}</div>
              <div>{t('landing_page.step.perform_desc')}</div>
            </div>
          </div>
          <div className={styles.stepImage}>
            <p className={styles.middleNumber}>3</p>
            <div className={styles.wrapImage}>
              <img src="/images/homev2/3_step/step_3.svg" alt="" />
              <div>{t('landing_page.step.enjoy')}</div>
              <div>{t('landing_page.step.enjoy_desc')}</div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(StepTrading);
