import { FC, memo, useEffect, useState } from 'react';
import styles from './BannerDesktop.module.less';
import commonstyles from '../common.module.css';
import { Col, message, Row } from 'antd';
import { useAppDispatch, useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import { setAuthModal } from 'store/ducks/system/slice';
import { useRouter } from 'next/router';
import { Button } from 'components/Button';
import Animation from './Animation';
import { useBannerType } from 'api/common';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isServer, USER_COOKIES } from 'utils/constant';
import { getCookies, setCookies } from 'utils/cookies';
import { carouselContents } from '../HeroSection/heroConfig';
import { CarouselV3 } from 'components/Carousel/CarouselV3';

const validateEmail = (email: string) => {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const Banner: FC = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { t } = useTypeSafeTranslation();
  const [email, setEmail] = useState<string>('');
  // const { md } = useBreakpoint();

  const { data } = useBannerType();

  useEffect(() => {
    if (!data || isServer()) return;
    const isSettled = getCookies(USER_COOKIES.banner_generated) === 'true';
    const wd = window as any;

    if (isSettled) {
      wd?.hj('event', data === 'animation' ? 'loading_banner_animation' : 'loading_banner_image');
    } else {
      wd?.hj('event', data === 'animation' ? 'home_page_animation_generated' : 'home_page_image_generated');
      setCookies(USER_COOKIES.banner_generated, 'true');
    }
  }, [data]);

  const handleRegister = () => {
    (window as any)?.hj('event', data === 'animation' ? 'home_page_register_animation' : 'home_page_register_image');
    if (!!email) {
      const valid = validateEmail(email);
      if (!valid) {
        message.error('Email is not valid');
        return;
      }
      router.replace({ pathname: router.pathname, query: { email: email } }, undefined, { shallow: true });
    }
    dispatch(setAuthModal({ type: 'signup', screen: 'banner' }));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleRegister();
    }
  };

  return (
    <div className="container">
      <Row className={clsx(commonstyles.root, styles.banner)}>
        <div className={styles.background}>
          <div className={styles.left}></div>
          <div className={styles.right}></div>
        </div>
        <Col className={styles.wrapTitle} lg={12} md={24} xs={24}>
          <h2 className={styles.title}>
            <span>{t('landing_page.banner.ultorex')}</span> {t('landing_page.banner.sub_title')}&nbsp;
            <strong>{t('landing_page.banner.upto')}</strong>.
          </h2>
          <div className={styles.registerWrapper}>
            <input
              onKeyDown={handleKeyDown}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('footer.subscriber.your_email')}
            />
            <div className={styles.btn}>
              <Button onClick={handleRegister} size="large" type="primary">
                <span>{t('landing_page.button.register')}</span>
                <span>{t('landing_page.button.start')}</span>
              </Button>
            </div>
          </div>
        </Col>
        <Col className={styles.wrapImage} lg={12} md={24} xs={24}>
          {data === 'animation' ? <Animation /> : <img alt="bull-with-coin" src="/images/homev2/banner_1.svg" />}
        </Col>
        <CarouselV3 contents={carouselContents} />
      </Row>
    </div>
  );
};

export default memo(Banner);
