import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button as ButtonAntd, Carousel, Col, Row } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import clsx from 'clsx';
import Hide from 'components/Hide';
import { FC, ReactNode, useRef, useState } from 'react';
import styles from './CarouselV2.module.less';
import { useAppDispatch, useTypeSafeTranslation } from 'hooks';
import { setAuthModal } from 'store/ducks/system/slice';

export interface Item {
  image: string;
  title: string;
  text?: string;
  index: number;
  className?: string;
  type?: string;
}

interface CarouselProps {
  contents: Item[];
  // slides: { index: number; component: ReactNode | JSX.Element }[];
}

export const Item: FC<Item> = ({ title, text, image, className, type }) => {
  const { t } = useTypeSafeTranslation();
  const dispatch = useAppDispatch();

  return (
    <div
      className={clsx(className, type && styles.largeItem)}
      onClick={() => {
        dispatch(setAuthModal({ type: 'login', screen: 'banner' }));
      }}
    >
      <div className={styles.wrapImage}>
        <img src={image} alt="" />
      </div>
      <div className={styles.wrapTitle}>
        <div className={styles.mainTitle}>{t(title as any)}</div>
        <div className={styles.subTitle}>{t(text as any)}</div>
      </div>
    </div>
  );
};

export const CarouselV3: FC<CarouselProps> = ({ contents }) => {
  const carouSelTopRef = useRef<CarouselRef>(null);
  const carouselRef = useRef<CarouselRef>(null);
  const [activeIdx, setActiveIdx] = useState(0);

  const numOfChildren = contents.length;
  const goNext = () => {
    carouselRef.current?.next();
  };

  const goPrev = () => {
    carouselRef.current?.prev();
  };

  return (
    <div className={styles.wrapCarousel} style={{ marginTop: 100 }}>
      <div className={styles.bottomCarousel}>
        <Hide if={numOfChildren <= 1}>
          <ButtonAntd
            icon={<LeftOutlined />}
            shape="circle"
            type="text"
            size="large"
            onClick={goPrev}
            className="primary"
          />
        </Hide>
        <div className={styles.carousel}>
          <Carousel
            key="2"
            slidesToShow={3}
            autoplay={true}
            autoplaySpeed={5000}
            centerMode
            draggable
            swipeToSlide
            touchThreshold={50}
            focusOnSelect
            ref={carouselRef}
            centerPadding="12px"
            beforeChange={(_, to) => {
              setActiveIdx(to);
              carouSelTopRef.current?.goTo(to);
            }}
          >
            {contents.map((item) => (
              <Item
                className={clsx(activeIdx === item.index && styles.activeItem, styles.item)}
                key={item.index}
                image={item.image}
                title={item.title}
                text={item.text}
                index={item.index}
              />
            ))}
          </Carousel>
        </div>
        <Hide if={numOfChildren <= 1}>
          <ButtonAntd
            icon={<RightOutlined />}
            shape="circle"
            type="text"
            size="large"
            onClick={goNext}
            className="primary"
          />
        </Hide>
      </div>
    </div>
  );
};
