import { request } from 'api/axios';
import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { USER_COOKIES } from 'utils/constant';
import { getCookies, removeCookies, setCookies } from 'utils/cookies';
import { BannerType } from './types';

export const useBannerType = (options?: UseQueryOptions<BannerType>) => {
  return useQuery<BannerType>(
    '/api/banner',
    async () => {
      const { data } = await request.post('/getBanner/test');
      return Number(data) === 1 ? 'animation' : 'image';
    },
    {
      initialData: getCookies(USER_COOKIES.banner) as BannerType,
      onSuccess: (data) => {
        setCookies(USER_COOKIES.banner, data);
      },
      onError: (data) => {
        removeCookies(USER_COOKIES.banner);
      },
      enabled: !getCookies(USER_COOKIES.banner),
      ...options,
    }
  );
};
