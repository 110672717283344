import clsx from 'clsx';
import { FC } from 'react';
import styles from './styles.module.less';
import commonStyles from '../rightPanel.module.less';
import { Trans } from 'next-i18next';
import { useTypeSafeTranslation } from 'hooks';
import { MobileDevice } from '../../LeftPannel/MobileDevice';
import { Hidden } from '@mui/material';

export const RightMobile: FC = () => {
  const { t } = useTypeSafeTranslation();
  return (
    <>
      <div className={commonStyles.largeItem}>
        <div className={commonStyles.wrapImage}>
          <img src="/images/home/mobile/banner.png" alt="" />
        </div>
        <div className={commonStyles.wrapTitle}>
          <div className={clsx(commonStyles.mainTitle, styles.deviceTitle)}>
            <Trans
              i18nKey={'landing_page.banner.title_right_mobile'}
              t={t as any}
              components={[<span key="0" />, <span key="1" />, <span key="2" />, <span className="light" key="3" />]}
            />
          </div>
          <Hidden mdDown>
            <MobileDevice />
          </Hidden>
        </div>
      </div>

      <Hidden mdUp>
        <MobileDevice />
      </Hidden>
    </>
  );
};
