import clsx from 'clsx';
import { FC } from 'react';
import styles from './styles.module.less';
import commonStyles from '../rightPanel.module.less';
import { Trans } from 'next-i18next';
import { useTypeSafeTranslation } from 'hooks';
import { routes } from 'types/routes';
import Link from 'next/link';
import { Button } from 'components/Button';
import { Hidden } from '@mui/material';

export const RightAvax: FC = () => {
  const { t } = useTypeSafeTranslation();

  return (
    <>
      <div className={commonStyles.largeItem}>
        <div className={commonStyles.wrapImage}>
          <img src="/images/home/avax_blended.png" alt="" />
        </div>
        <div className={commonStyles.wrapTitle}>
          <div className={clsx(commonStyles.mainTitle, styles.titleSweat)}>
            <Trans
              i18nKey={'landing_page.banner.title_right_avax'}
              t={t as any}
              components={[<span key="0" />, <span key="1" />, <span key="2" />, <span className="light" key="3" />]}
            />
          </div>

          <Hidden mdDown>
            <div className="f-end">
              <div className="transformButton">
                <Link href={`${routes.stake}`} passHref>
                  <div className="btn">
                    <Button size="large" type="primary">
                      <span>{t('landing_page.button.stake')}</span>
                      <span>{t('landing_page.button.start')}</span>
                    </Button>
                  </div>
                </Link>
              </div>
            </div>
          </Hidden>
        </div>
      </div>

      <Hidden mdUp>
        <div className="f-center">
          <div className="transformButton">
            <Link href={`${routes.stake}`} passHref>
              <div className="btn">
                <Button size="large" type="primary">
                  <span>{t('landing_page.button.stake')}</span>
                  <span>{t('landing_page.button.start')}</span>
                </Button>
              </div>
            </Link>
          </div>
        </div>
      </Hidden>
    </>
  );
};
