import clsx from 'clsx';
import { FC } from 'react';
import styles from './styles.module.less';
import { useTypeSafeTranslation } from 'hooks';
import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

export const MobileDevice: FC = () => {
  const { t } = useTypeSafeTranslation();
  const { md } = useBreakpoint();

  return (
    <>
      <Row className="mt-24" align="middle" gutter={[14, 14]} justify={!md ? 'center' : 'space-between'}>
        <Col>
          <p className={styles.label}>
            <i className="regular">{t('landing_page.slider.available')}</i>
          </p>

          <a
            href="https://play.google.com/store/apps/details?id=com.ultorexapp.io"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(styles.button, styles.btnClickable)}
          >
            <img src="/images/home/mobile/google-play.png" alt="" />
          </a>
        </Col>
        <Col>
          <p className={styles.label}>
            <i className="regular">{t('landing_page.slider.available')}</i>
          </p>
          <a
            href="https://apps.apple.com/us/app/ultorex/id1625009146"
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(styles.button, styles.btnClickable)}
          >
            <img src="/images/home/mobile/appstore.png" alt="" />
          </a>
        </Col>
      </Row>
    </>
  );
};
