import { FC, memo } from 'react';
import styles from './PopularCrypto.module.less';
import commonStyles from '../common.module.css';
import { Col, Row } from 'antd';
import { useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import { RightOutlined } from '@ant-design/icons';
import MainMarket from 'modules/MarketPage/components/MainMarket';
import Link from 'next/link';
import { routes } from 'types/routes';

const PopularCrypto: FC = () => {
  const { t } = useTypeSafeTranslation();
  return (
    <div className="container">
      <Row className={clsx(commonStyles.root)}>
        <Col className={styles.wrapTitle} xs={24} lg={24}>
          <h2 className={styles.title}>{t('landing_page.popular.title')}</h2>
          <Link href={`${routes.market}`} passHref>
            <a className={styles.viewLink}>
              {t('common.view_more')} <RightOutlined />
            </a>
          </Link>
        </Col>
        <Col xs={24} lg={24}>
          <div className={styles.mainMarket}>
            <MainMarket limit />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default memo(PopularCrypto);
