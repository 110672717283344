import { RightSweatPanel } from './RightPanel/SweatListing/RightSweatPanel';
import { RightMobile } from './RightPanel/MobileDevice.tsx/RightMobile';
import { RightAvax } from './RightPanel/AvaxListing/RightAvax';
import { AtomCampaign } from './RightPanel/AtomCampaign';
import { ORBSTop10 } from './RightPanel/ORBSTop10';
import { SOLCampaign } from './RightPanel/SOLCampaign';
import RightStakeEarn from './RightPanel/StakeEarn/RightStakeEarn';

export const carouselContents = [
  // {
  //   index: 0,
  //   // image: '/images/homev2/sol-campaign/sub_logo.png',
  //   image: '/images/coins/sol.svg',
  //   title: 'SOL Staking Competition',
  //   text: 'Up to 30 SOL and staking reward up to 12% APR',
  // },
  // {
  //   index: 0,
  //   image: '/images/stake-earn/icon.png',
  //   title: 'landing_page.slider_content_stake',
  //   text: 'landing_page.slider_text_stake',
  // },
  {
    index: 0,
    image: '/images/orbs-top-10/sub_logo.png',
    title: 'ORBS Special Page!',
    text: 'Stake & be in the top 10 to get 8.5% APY',
  },
  // {
  //   index: 2,
  //   image: '/images/home/atom_campaign.png',
  //   title: 'landing_page.slider_content_atom_campaign',
  //   text: 'landing_page.slider_text_atom_campaign',
  // },
  {
    index: 1,
    image: '/images/home/sweat.png',
    title: 'landing_page.slider_content_sweat',
    text: 'landing_page.slider_text_sweat',
  },
  {
    index: 2,
    image: '/images/home/mobile/banner.png',
    title: 'landing_page.slider_content_mobile',
  },
  {
    index: 3,
    image: '/images/home/avax_blended.png',
    title: 'landing_page.slider_content_avax',
    text: 'landing_page.slider_text_avax',
  },
];

export const carouselSlides = [
  // { index: 0, component: <SOLCampaign key={0} /> },
  // { index: 2, component: <AtomCampaign key={2} /> },
  // { index: 0, component: <RightStakeEarn key={0} /> },
  { index: 1, component: <ORBSTop10 key={0} /> },
  { index: 2, component: <RightSweatPanel key={1} /> },
  { index: 3, component: <RightMobile key={2} /> },
  { index: 4, component: <RightAvax key={3} /> },
];
