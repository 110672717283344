import { FC, memo } from 'react';

import Lottie from 'lottie-react';
import animation from 'assets/lotties/ultorex_headerAnimation.json';

const Animation: FC = () => {
  return <Lottie animationData={animation} />;
};

export default memo(Animation, () => false);
