import { FC, useEffect } from 'react';
// import { ActionBanners } from './components/ActionBanners';
import styles from './styles.module.less';
import BannerDesktop from './components/Banner/BannerDesktop';
import PopularCrypto from './components/PopularCrypto/PopularCrypto';
import StepTrading from './components/StepTrading/StepTrading';
import Feature from './components/Feature/Feature';
import MobileApp from './components/MobileApp/MobileApp';
import RoadMap from './components/RoadMap/RoadMap';
import HeroSection from './components/HeroSection';
import MarketReport from './components/MarketReport/MarketReport';
import { useUser } from '@auth0/nextjs-auth0';
import { isServer } from 'utils/constant';

const LandingPage: FC = () => {
  const { user } = useUser();

  useEffect(() => {
    if (isServer()) return;
    const wd = window as any;
    if (user) {
      wd?.hj('event', 'landing_page_login_user');
    } else {
      wd?.hj('event', 'landing_page_guest_user');
    }
  }, [user]);

  useEffect(() => {
    console.log('hello version:1.1.3');
  }, []);

  return (
    <div className={styles.root}>
      {user && (
        <>
          <HeroSection />
          <PopularCrypto />
          <MarketReport />
          <RoadMap />
          <MobileApp />
          <Feature />
        </>
      )}
      {!user && (
        <>
          <BannerDesktop />
          <PopularCrypto />
          <StepTrading />
          <Feature />
          <MobileApp />
        </>
      )}
    </div>
  );
};

export default LandingPage;
