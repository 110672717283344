import { CSSProperties, FC, memo, ReactNode } from 'react';
import styles from './RoadMap.module.less';
import commonstyles from '../common.module.css';
import { Col, Row } from 'antd';
import { Button } from 'components/Button';

import { useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import React from 'react';
import Link from 'next/link';
import LinkTypo from 'antd/lib/typography/Link';

import { routes } from 'types/routes';
const RoadMap: FC = () => {
  const { t } = useTypeSafeTranslation();
  return (
    <div className="container">
      <Row className={clsx(commonstyles.root)}>
        <div className={styles.title}>{t('landing_page.roadmap.title')}</div>
        <Row>
          <Col className={styles.content} xl={8} lg={12} md={12} sm={24}>
            <div className={styles.smallTitle}>{t('landing_page.roadmap.small_title')}</div>
            <div className={styles.description}>{t('landing_page.roadmap.description')}</div>
            <div>
              <Link href={`${routes.upcoming}`} passHref>
                <div className={styles.btnIost}>
                  <Button size="large" type="primary">
                    <span>{t('landing_page.roadmap.button')}</span>
                  </Button>
                </div>
              </Link>
              <a
                href="https://support.ultorex.io/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.linkRequest}
              >
                <span>{t('landing_page.roadmap.text_request')}</span>
              </a>
            </div>
          </Col>
          <Col className={styles.wrapImage} xl={16} lg={12} md={12} sm={24}>
            <img src="/images/homev2/roadmap/banner.svg" alt="" />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default memo(RoadMap);
