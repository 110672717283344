import { FC, ReactNode } from 'react';
import styles from './Feature.module.less';
import commonstyles from '../common.module.css';
import { Col, Row } from 'antd';
import { Button } from 'components/Button';
import { useAppDispatch, useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import Link from 'next/link';
import { routes } from 'types/routes';
import { useUser } from '@auth0/nextjs-auth0';
import Hide from 'components/Hide';
import { setAuthModal } from 'store/ducks/system/slice';
interface FeatureItemProps {
  text: string;
  title: string;
  image: string;
  button?: ReactNode;
}

export const FeatureItem: FC<FeatureItemProps> = ({ text, title, image, button }) => {
  return (
    <Col className={styles.wrapFeature} xl={8} lg={12} md={12} sm={24} xs={24}>
      {/* <div className={styles.background}></div> */}
      <div className={styles.wrapImage}>
        <img src={image} alt="" />
        <span className={styles.smallTitle}>{title}</span>
      </div>
      <span className={styles.description}>{text}</span>
      <div className={styles.wrapButton}>{button}</div>
    </Col>
  );
};
const Feature: FC = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const { t } = useTypeSafeTranslation();
  return (
    <div className={clsx(!user.user && commonstyles.background)}>
      <div className={clsx('container', styles.features)}>
        <Row className={clsx(commonstyles.root)}>
          <div className={styles.wrapTitle}>
            <div>{t('landing_page.feature.title_hl')}&nbsp;</div>
            <div>{t('landing_page.feature.title')}</div>
          </div>
          <Row gutter={[25, 56]}>
            <FeatureItem
              title={t('landing_page.feature.transaction')}
              text={t('landing_page.feature.transaction_content')}
              image={'/images/homev2/infinity/flight.svg'}
            />
            <FeatureItem
              title={t('landing_page.feature.environment')}
              text={t('landing_page.feature.environment_content')}
              image={'/images/homev2/infinity/customizable.svg'}
              // button={<Button>{t('landing_page.feature.environment_button')}</Button>}
            />
            <FeatureItem
              title={t('landing_page.feature.staking')}
              text={t('landing_page.feature.staking_content')}
              image={'/images/homev2/infinity/apr_staking.svg'}
            />
            <FeatureItem
              title={t('landing_page.feature.trading')}
              text={t('landing_page.feature.trading_content')}
              image={'/images/homev2/infinity/subaccount.svg'}
            />
            <FeatureItem
              title={t('landing_page.feature.account')}
              text={t('landing_page.feature.account_content')}
              image={'/images/homev2/infinity/multi-chain.svg'}
            />
            <FeatureItem
              title={t('landing_page.feature.analysis')}
              text={t('landing_page.feature.analysis_content')}
              image={'/images/homev2/infinity/weekly-market.svg'}
              button={
                <a href="http://marketreports.ultorex.io/" target="_blank" rel="noopener noreferrer">
                  <Button>{t('landing_page.feature.analysis_button')}</Button>
                </a>
              }
            />
          </Row>
          <Hide if={!!user.user}>
            <div className={styles.wrapButtonTrade}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setAuthModal({ type: 'signup', screen: 'landing_bottom' }));
                }}
              >
                <div className={styles.btnAvax}>
                  <Button size="large" type="primary">
                    <span>{t('landing_page.button.register_utr')}</span>
                    <span>{t('landing_page.button.start')}</span>
                  </Button>
                </div>
              </a>
            </div>
          </Hide>
        </Row>
      </div>
    </div>
  );
};

export default Feature;
