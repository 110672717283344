import { FC, memo, useState } from 'react';
import styles from './styles.module.less';
import commonStyles from '../common.module.css';
import { Col, Row } from 'antd';

import { useTypeSafeTranslation } from 'hooks';
import clsx from 'clsx';
import React from 'react';
import { CarouselV2 } from 'components/Carousel/CarouselV2';
import Telegram from 'assets/svgs/components/Telegram';
import Twitter from 'assets/svgs/components/Twitter';
import { carouselContents, carouselSlides } from './heroConfig';

const Hero: FC = () => {
  const { t } = useTypeSafeTranslation();
  const [activeIdx, setActiveIdx] = useState(0);

  return (
    <div className="container relative">
      {activeIdx === 1 && (
        <img className={styles.orbsBackground} src="/images/homev2/orbs-top-10/banner_fg.png" alt="" />
      )}

      <Row className={clsx(commonStyles.root, styles.sellCrypto)}>
        <div className={styles.background}>
          <div
            className={clsx(styles.leftCircle, {
              [styles.circleLeftMobile]: activeIdx === 0,
              [styles.circleLeftSOL]: activeIdx === 1,
              [styles.circleLeftOrbs]: activeIdx === 2,
              [styles.circleLeftATOMCampaign]: activeIdx === 3,
              [styles.circleLeftSWEAT]: activeIdx === 4,
              [styles.circleLeftAvax]: activeIdx === 5,
            })}
          />
          <div
            className={clsx(styles.rightCircle, {
              [styles.circleRightMobile]: activeIdx === 0,
              [styles.circleRightSOL]: activeIdx === 1,
              [styles.circleRightOrbs]: activeIdx === 2,
              [styles.circleRightATOMCampaign]: activeIdx === 3,
              [styles.circleRightSWEAT]: activeIdx === 4,
              [styles.circleRightAvax]: activeIdx === 5,
            })}
          />
        </div>

        <CarouselV2 stateActiveIdx={[activeIdx, setActiveIdx]} slides={carouselSlides} contents={carouselContents} />

        <Col xl={24} lg={24} md={24} xs={24} className={styles.medias}>
          <a href="https://t.me/UltorexOfficial" target="_blank" rel="noopener noreferrer" className={styles.media}>
            <Telegram />
          </a>
          <a
            href="https://twitter.com/UltorexOfficial"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.media}
          >
            <Twitter />
          </a>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24} className={styles.text}>
          <span>{t('landing_page.slider.update')}</span>
        </Col>
      </Row>
    </div>
  );
};

export default memo(Hero);
